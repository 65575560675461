<template>
  <v-snackbar
    :value="!!msg"
    rounded="pill"
    top
    right
    transition="slide-y-reverse-transition"
    :color="alertType"
    @input="close"
  >
    {{ msg }}
    <template v-slot:action>
      <v-btn text @click="close">{{ $t('aClose') }}</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters(['alertType', 'msg']),
  },
  methods: {
    ...mapMutations(['setMsg', 'setAlertType']),
    close() {
      this.setMsg('');
    },
  },
  mounted() {
    this.setAlertType('info');
  },
};
</script>
