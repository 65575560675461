<template>
  <div>
    <template v-if="item !== '' && item !== null">
      <slot></slot>
    </template>
    <v-icon v-else dense color="secondary">{{ icons.mdiPencilOutline }}</v-icon>
  </div>
</template>

<script>
import { mdiPencilOutline } from '@mdi/js';

export default {
  props: {
    item: {
      type: [String, Number, Object],
      required: false,
    },
  },
  data: () => ({
    icons: { mdiPencilOutline },
  }),
};
</script>
