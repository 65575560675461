<template>
  <v-menu offset-y :close-on-content-click="false">
    <template ref="menu" v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon color="white" small>{{ icons.mdiFilterOutline }}</v-icon>
      </v-btn>
    </template>
    <v-card v-if="items.length > 0">
      <v-virtual-scroll
        :items="sortedItems"
        height="300"
        item-height="30"
      >
        <template v-slot:default="{ item, index }">
          <v-list-item :key="index" v-if="index !== countItems">
            <v-list-item-action>
              <v-checkbox
                :input-value="!exclude.includes(item)"
                @change="toogleArray(item)"
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <template v-if="format === 'date'">{{ formatUnix(item) }}</template>
                <template v-else-if="format === 'currency'">{{ $n(item, 'currency') }}</template>
                <template v-else>
                  {{ (item === '' || item === null) ? $t('wot.noValue') : item }}
                </template>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
      <v-card-actions class="pt-0">
        <v-btn text color="success" @click="toogleAll(true)">{{ $t('showAll') }}</v-btn>
        <v-btn text color="error" @click="toogleAll(false)">{{ $t('hideAll') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import {
  mdiFilterOutline,
} from '@mdi/js';
import functions from '@/resources/functions';

export default {
  mixins: [functions],
  props: {
    items: {
      type: Array,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    icons: {
      mdiFilterOutline,
    },
  }),
  computed: {
    ...mapFields('loadOrders', [
      'filterMenu.obrano',
      'filterMenu.dataobra',
      'filterMenu.u_transp',
      'filterMenu.nome',
      'filterMenu.u_cepos',
      'filterMenu.u_tipos',
      'filterMenu.u_carga',
      'filterMenu.u_carga2',
      'filterMenu.u_descarga',
      'filterMenu.u_descarg2',
      'filterMenu.u_quant2',
      'filterMenu.unidade',
      'filterMenu.u_prcl',
      'filterMenu.nft',
      'filterMenu.u_fttrans',
      'filterMenu.u_prtransp',
      'filterMenu.nft1',
      'filterMenu.u_fttransp',
      'filterMenu.u_comiss',
      'filterMenu.descarga',
      'filterMenu.carga',
      'filterMenu.u_equipa1',
      'filterMenu.u_tarefa1',
      'filterMenu.u_prc1',
      'filterMenu.u_equipa2',
      'filterMenu.u_tarefa2',
      'filterMenu.u_prc2',
      'filterMenu.u_equipa3',
      'filterMenu.u_tarefa3',
      'filterMenu.u_prc3',
      'filterMenu.u_matric',
      'filterMenu.u_mata',
      'filterMenu.u_tarefa',
      'filterMenu.u_prc',
      'filterMenu.u_ftequip',
      'filterMenu.u_mataalt',
      'filterMenu.lobs',
    ]),
    exclude() {
      return this[this.fieldName];
    },
    allChecked() {
      return this.exclude.length === 0;
    },
    countItems() {
      return this.items.length;
    },
    sortedItems() {
      return this.items.slice().sort((a, b) => a - b);
    },
  },
  methods: {
    toogleArray(item) {
      this[this.fieldName] = this[this.fieldName].includes(item)
        ? this[this.fieldName].filter((i) => i !== item)
        : [...this[this.fieldName], item];
    },
    toogleAll(show) {
      if (show) {
        this[this.fieldName] = [];
      } else {
        this[this.fieldName] = this.items;
      }
    },
  },
};
</script>
