import dayjs from '@/plugins/dayjs';

export default {
  data: () => ({
    uCeposOptions: [
      'GR-',
      'NE-S',
      'NE-F',
      'NT-A',
      'GE-U',
    ],
  }),
  methods: {
    formatUnix(unix) {
      return dayjs.unix(unix).format('DD/MM/YY');
    },
    formatUnixToDate(unix) {
      return dayjs.unix(unix).format('YYYY-MM-DD');
    },
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },
    explodeEmails(emails) {
      if (emails) {
        const newstr = emails.replace(',', ';');

        return newstr.split(';');
      }

      return [];
    },
    displayTotal({ val, unidade }) {
      const places = (unidade === 'TON' || unidade === 'M3') ? 3 : 2;

      return `${this.$t('total')} ${unidade}: ${val.toFixed(places)}`;
    },
  },
};
