<template>
  <div>
    <v-btn
      v-scroll="onScroll" v-show="fab" fab small dark fixed bottom right outlined color="primary"
      @click="toTop" class="mb-90"
    >
      <v-icon dark>{{ icons.mdiChevronUp }}</v-icon>
    </v-btn>
    <v-btn
      v-scroll="onScroll" v-show="fab" fab small dark fixed bottom right outlined color="primary"
      @click="toBottom" class="mb-10"
    >
      <v-icon dark>{{ icons.mdiChevronDown }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mdiChevronUp, mdiChevronDown } from '@mdi/js';

export default {
  data: () => ({
    fab: false,
    icons: { mdiChevronUp, mdiChevronDown },
  }),
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') {
        return;
      }

      const top = window.pageYOffset || e.target.scrollTop || 0;

      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    toBottom() {
      this.$vuetify.goTo(document.body.scrollHeight);
    },
  },
};
</script>

<style scoped>
.mb-90 {
  margin-bottom: 90px;
}
button {
  border: 3px solid #1976d2;
  background: rgb(27 8 189 / 14%)
}
</style>
