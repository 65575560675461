<template>
  <v-dialog :value="true" max-width="700px" @click:outside="setEmailDialog(false)">
    <v-card>
      <v-card-title class="primary white--text">
        <span class="text-h5">
          {{ type === 'client' ? $t('sendEmail') : $t('sendList') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <template v-for="item in attachmentsList">
            <v-checkbox
              v-for="att in item"
              :key="att.anexosstamp"
              v-model="selected"
              :label="att.resumo"
              :value="att.anexosstamp"
            />
          </template>
        </v-container>
        <validation-observer ref="observer" v-slot="{ passes }" v-if="type === 'client'">
          <v-form @submit.prevent="passes(clickSendEmail)">
            <validation-provider v-slot="{ errors }" name="emailSubject" rules="required">
              <v-text-field
                v-model="emailSubject"
                :label="`${$t('fields.emailSubject')} *`"
                :error-messages="errors"
                outlined
                dense
                counter="100"
                maxlength="100"
              />
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="emailBody" rules="required">
              <v-textarea
                :label="`${$t('fields.emailBody')} *`"
                v-model="emailBody"
                :error-messages="errors"
                outlined
                dense
                counter="1000"
                maxlength="1000"
                persistent-hint
                :hint="$t('emailBodyHint')"
              />
            </validation-provider>
          </v-form>
        </validation-observer>
        <div class="mt-5 font-weight-bold">
          {{
            type === 'client'
            ? $t('confirmSendAttchments', { emails })
            : $t('confirmSendList', { count, emails })
          }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="setEmailDialog(false)">{{ $t('aClose') }}</v-btn>
        <v-btn
          text
          color="primary"
          @click="clickSendEmail"
          :disabled="emails.length === 0 || count === 0"
        >
          {{ $t('aSend') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    emails: {
      type: Array,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    keyword: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    bostamps: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    selected: [],
    emailSubject: '',
    emailBody: '',
  }),
  computed: {
    ...mapFields('loadOrders', ['filteredStamps']),
    ...mapGetters('loadOrders', ['attachmentsList']),
    listBostamps() {
      return this.bostamps.filter((el) => !this.filteredStamps.includes(el));
    },
    message: {
      get() {
        return this.msg;
      },
      set(value) {
        this.setMsg(value);
      },
    },
  },
  methods: {
    ...mapActions('loadOrders', ['sendListEmail', 'showAttachmentsList']),
    ...mapMutations('loadOrders', ['setEmailDialog']),
    ...mapMutations(['setLoading']),
    async clickSendEmail() {
      this.setLoading(true);

      let isValid = await this.$refs?.observer?.validate();

      if (this.type !== 'client') {
        isValid = true;
      }

      if (isValid) {
        await this.sendListEmail({
          ...this.options,
          keyword: this.keyword,
          emails: this.emails,
          type: this.type,
          attachments: this.selected,
          emailBody: this.emailBody,
          emailSubject: this.emailSubject,
        });
      }

      this.setLoading(false);
    },
  },
  async mounted() {
    if (this.listBostamps.length > 0) {
      this.setLoading(true);

      await this.showAttachmentsList({ bostamps: this.listBostamps });

      this.setLoading(false);
    }
  },
};
</script>
